<template>
  <signup-template :progress-amount="20" data-test="layout">
    <template #text>
      <h1 class="company__title" data-test="title">
        {{ $t(`${langPath}.title`) }}
      </h1>
      <h2 class="company__description" data-test="description">
        {{ $t(`${langPath}.description`) }}
      </h2>

      <signup-switch-to-english />
    </template>

    <template #form>
      <section class="company__form" data-test="form">
        <ev-input
          v-model="employerName"
          :placeholder="$t(`${langPath}.placeholders.employerName`)"
          :label="$t(`${langPath}.labels.employerName`)"
          :errors="errorMessages($v.employerName)"
          data-test="employer-name-input"
          name="employerName"
          class="company__input"
          @blur="$v.employerName.$touch()"
        />
        <ev-input
          v-model="name"
          :placeholder="$t(`${langPath}.placeholders.name`)"
          :label="$t(`${langPath}.labels.name`)"
          :errors="errorMessages($v.name)"
          data-test="company-name-input"
          name="name"
          class="company__input"
          @blur="$v.name.$touch()"
        />

        <p class="company__label-text">{{ $t(`${langPath}.labels.companyHasCnpj`) }}</p>

        <div class="company__radio">
          <ev-radio
            id="has-cnpj-true"
            :value="hasCnpjInputValue"
            data-test="has-cnpj-true"
            checked="true"
            name="hasCnpjTrue"
            :label="$t(`${langPath}.labels.hasCnpjTrue`)"
            color="blue"
            class="company__radio-button"
            @input="setCnpj(true)"
          />
          <ev-radio
            id="has-cnpj-false"
            :value="hasCnpjInputValue"
            checked="false"
            data-test="has-cnpj-false"
            name="hasCnpjFalse"
            :label="$t(`${langPath}.labels.hasCnpjFalse`)"
            color="blue"
            class="company__radio-button"
            @input="setCnpj(false)"
          />
        </div>

        <ev-input
          v-model="phone"
          :placeholder="'(11) 00000-0000'"
          :label="$t(`${langPath}.labels.phone`)"
          :errors="errorMessages($v.phone)"
          v-mask="['(##) ####-####', '(##) #####-####']"
          data-test="company-phone-input"
          name="phone"
          class="company__input"
          @blur="$v.phone.$touch()"
        />

        <ev-button
          full-width
          :disabled="isNextDisabled"
          data-test="submit-button"
          data-ga-id="clicked_on_completed_singup"
          @click="submitForm"
        >
          {{ $t(`${langPath}.nextButton`) }}
        </ev-button>
      </section>
    </template>
  </signup-template>
</template>

<script>
import SignupTemplate from './components/SignupTemplate'
import SignupSwitchToEnglish from './components/SignupSwitchToEnglish'
import EvButton from '@revelotech/everestV2/EvButton'
import EvInput from '@revelotech/everestV2/EvInput'
import EvRadio from '@revelotech/everestV2/EvRadio'

import { createNamespacedHelpers } from 'vuex'
import { COMPANY, CURRENT_USER } from '@/store/namespaces'

import { required } from 'vuelidate/lib/validators'

import { toCamelCase } from '@/helpers/case-style'
import deepObjectLooper from '@/helpers/deep-object-mapper'

const companyHelper = createNamespacedHelpers(COMPANY)
const currentUserHelper = createNamespacedHelpers(CURRENT_USER)

export default {
  name: 'SignupCompany',
  components: {
    EvButton,
    EvInput,
    EvRadio,
    SignupSwitchToEnglish,
    SignupTemplate
  },
  data () {
    return {
      langPath: __langpath,
      employerName: '',
      name: '',
      phone: '',
      planChoice: '',
      signupSource: '',
      hasCnpj: undefined,
      googleService: null
    }
  },
  computed: {
    ...currentUserHelper.mapState(['currentUser']),
    isNextDisabled () {
      return !this.name || !this.phone || !this.hasCnpj
    },
    hasCnpjInputValue () {
      return this.hasCnpj?.toString() || ''
    }
  },
  validations () {
    return {
      employerName: { required },
      name: { required },
      phone: { required }
    }
  },
  created () {
    const query = deepObjectLooper(this.$route.query, toCamelCase)

    this.employerName = query.name
    this.phone = query.phone
    this.name = query.company
    this.signupSource = query.signupSource
    this.planChoice = query.planChoice
  },
  methods: {
    ...companyHelper.mapActions(['createCompany']),
    async submitForm () {
      const formData = {
        employerName: this.employerName,
        name: this.name,
        companyPhone: this.phone.replace(/[()-]/g, ''),
        signupSource: this.signupSource,
        planChoice: this.planChoice,
        hasCnpj: this.hasCnpj
      }
      this.createCompany(formData)
        .then((response) => {
          window.location.assign(response.links.next)
        })
        .catch((failure) => {
          this.$rollbar.error(failure)
        })
    },
    setCnpj (value) {
      this.hasCnpj = value
    }
  }
}
</script>

<style lang="scss" scoped>
.company {
  &__title {
    @extend %h4;

    @include margin(bottom, 4);

    color: var(--tx-neutral-blank);
  }

  &__description {
    @extend %h6;

    color: var(--tx-neutral-blank);
  }

  &__form {
    background-color: var(--bg-neutral-blank);
    height: 100%;
    padding: $base*4;
  }

  &__label {
    font-size: var(--tx-body-small);
    margin-bottom: $base*2;
    margin-top: $base*2;
  }

  &__input {
    margin-bottom: $base*6;
    margin-top: $base*2;
  }

  @include breakpoint(md) {
    &__form {
      padding: $base*60 $base*33;
    }

    &__label {
      font-size: var(--tx-body-small);
      margin-bottom: $base*2;
      margin-top: $base*6;
    }

    &__title {
      font-size: $base*8;
      line-height: $base*10;
    }

    &__description {
      font-size: $base*6;
      line-height: $base*8;

      &-link {
        position: absolute;
        top: $base*130;
      }
    }

    &__label-text {
      font-size: 14px;
      line-height: $base*5;
      margin-bottom: $base*2;
    }
  }

  &__radio {
    display: flex;
    font-size: 14px;
    line-height: $base*5;
    padding-top: $base;

    &-button {
      align-items: center;
      display: inline-flex;
      margin-bottom: $base*3;
      margin-right: $base*6;
    }
  }
}
</style>
